@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&family=Playfair+Display&family=Satisfy&display=swap");

body {
  /* font-family: "Pacifico", cursive; */
  /* font-family: "Playfair Display", serif; */
  /* font-family: "Satisfy", cursive; */
  /* font-family: "Playfair Display", serif; */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Login CSS */

@import url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWLnmbiM9DE99l1_eZR6EHUx12uTuNPXRJZMYWdbzkrbXYV6m6SfPV4t0oMpC8AYIe8Nc&usqp=CAU);

* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
section .container {
  width: 100%;
  height: 100vh;
  display: flex;
}
section .imgBx {
  position: relative;
  width: 50%;
  height: 100%;
}
section .imgBx:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(225deg, #7451f8 #7451f8);
  z-index: 1;
  mix-blend-mode: screen;
}
section .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
section .contentBx {
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;

  width: 50%;
  height: 100%;
}
section .contentBx .formBx {
  width: 50%;
}
section .contentBx .formBx h2 {
  color: #607d8b;
  font-weight: 600;
  font-size: 1.5em;
  /* margin-left: 130px; */
  text-transform: uppercase;
  margin-bottom: 30px;
  border-bottom: 4px solid #7451f8;
  display: inline-block;
  letter-spacing: 1px;
}
section .contentBx .formBx h4 {
  color: #607d8b;
  font-weight: 600;
  font-size: 1.3em;
  margin-left: 130px;
  text-transform: uppercase;
  margin-bottom: 20px;
  border-bottom: 4px solid #7451f8;
  display: inline-block;
  letter-spacing: 1px;
}
section .contentBx .formBx h5 {
  color: #607d8b;
  font-weight: 600;
  font-size: 1.3em;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;
  /* border-bottom: 4px solid #7451f8; */
  /* display: inline-block; */
  letter-spacing: 1px;
}
section .contentBx .formBx .inputBx {
  margin-bottom: 20px;
}
section .contentBx .formBx .inputBx span {
  font-size: 16px;
  margin-bottom: 5px;
  display: inline-block;
  color: #607db8;
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 1px;
}
section .contentBx .formBx .inputBx input {
  width: 100%;
  padding: 10px 20px;
  outline: none;
  font-weight: 400;
  border: 1px solid #607d8b;
  font-size: 16px;
  letter-spacing: 1px;
  color: #607d8b;
  background: transparent;
  border-radius: 30px;
}
section .contentBx .formBx .inputBx input[type="submit"] {
  background-color: #7451f8;
  color: #fff;
  outline: none;
  border: none;
  font-weight: 500;
  cursor: pointer;
}
section .contentBx .formBx .inputBx input[type="submit"]:hover {
  background: #7451f8;
}

/* signin with google */

section .contentBx .formBx .inputBx button {
  width: 100%;
  padding: 10px 20px;
  outline: none;
  font-weight: 400;
  border: 1px solid #607d8b;
  font-size: 16px;
  letter-spacing: 1px;
  color: #607d8b;
  background: transparent;
  border-radius: 30px;
}
section .contentBx .formBx .inputBx button[type="submit"] {
  background-color: #d80707;
  color: #fff;
  outline: none;
  border: none;
  font-weight: 500;
  cursor: pointer;
}
section .contentBx .formBx .inputBx button[type="submit"]:hover {
  background: #d80707;
}

section .contentBx .formBx .inputBx button[type="submit"]:hover {
  background: #d80707;
}

section .contentBx .formBx .remember {
  margin-bottom: 10px;
  color: #607d8b;
  font-weight: 400;
  font-size: 14px;
}
section .contentBx .formBx .inputBx p {
  color: #607d8b;
}
section .contentBx .formBx .inputBx p a {
  color: #7451f8;
}
